/* eslint-disable no-unused-vars */
import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import ComingSoonView from "../views/ComingSoonView.vue";
import ChangeLogView from "../views/ChangeLogView.vue";

function createGuard(minUserRole: number) {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const hasToken = localStorage.getItem("token");
    let isAuthenticated;

    const userRole = Number(localStorage.getItem("role"));

    if (userRole >= minUserRole && hasToken) {
      isAuthenticated = true;
    } else {
      isAuthenticated = false;
    }
    if (isAuthenticated) {
      next();
    } else {
      if (to.name === "login - view asset") {
        next();
      } else {
        next("/dashboard"); // pushed back home;
      }
    }
  };
}

// const guardMyManagerRoute = createGuard(1);
const guardMySuperAdminRoute = createGuard(2);

function guardMyRoute(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  // Grab the token value
  const loggedIn = localStorage.getItem("loggedUser") === "true";
  const hasToken = localStorage.getItem("token");

  //Create a local var
  let isAuthenticated: boolean;

  //Check the loggedIn var is not false
  if (loggedIn && hasToken)
    // Set Local to true
    isAuthenticated = true;
  //If false set local to false
  else isAuthenticated = false;

  //Handle route destination
  if (isAuthenticated) {
    //Move forward
    next();
  } else {
    //Push to login
    next("/");
  }
}

function viewAssetGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  // Grab the token value
  const loggedIn = localStorage.getItem("loggedUser") === "true";
  const hasToken = localStorage.getItem("token");

  //Create a local var
  let isAuthenticated;

  //Check the loggedIn var is not false
  if (loggedIn && hasToken)
    // Set Local to true
    isAuthenticated = true;
  //If false set local to false
  else isAuthenticated = false;

  //Handle route destination
  if (isAuthenticated) {
    //Move forward
    next();
  } else {
    next({
      name: "login - view asset", // replace with the actual name of the route
      params: { assetId: to.params.assetId },
      query: { asset: to.query.asset },
    });
  }
}

const routes = [
  {
    path: "/login/asset/:assetId",
    name: "login - view asset",
    component: () => import("../views/ViewAssetLogin.vue"),
    // component: ComingSoonView,
    meta: {
      title: "Login - View Asset",
      metaTags: [
        {
          name: "description",
          content: "Login - View Asset",
        },
      ],
    },
  },
  {
    path: "/",
    name: "login",
    component: () => import("../userJourney/views/LoginView.vue"),
    meta: {
      title: "Tagr - Login",
      metaTags: [
        {
          name: "description",
          content: "Tagr - Login",
        },
      ],
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../userJourney/views/LogoutView.vue"),
  },
  {
    path: "/register",
    name: "register account",
    component: () => import("../userJourney/views/CreateAccountView.vue"),
    meta: {
      title: "Tagr - Register Account",
      metaTags: [
        {
          name: "description",
          content: "Tagr - Register Account",
        },
      ],
    },
  },
  {
    path: "/forgot-password",
    name: "forgot password",
    component: () => import("../userJourney/views/ForgotPasswordView.vue"),
    meta: {
      title: "Tagr - Forgot Password",
      metaTags: [
        {
          name: "description",
          content: "Tagr - Forgot Password",
        },
      ],
    },
  },
  {
    path: "/reset-password",
    name: "reset password",
    component: () => import("../userJourney/views/ResetPassword.vue"),
    meta: {
      title: "Tagr - Reset Password",
      metaTags: [
        {
          name: "description",
          content: "Tagr - Reset Password",
        },
      ],
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../components/HomeDashboard.vue"),
    // component: ComingSoonView,
    // beforeEnter: guardMyRoute,
    meta: {
      title: "Dashboard",
      metaTags: [
        {
          name: "description",
          content: "Dashboard",
        },
      ],
    },
  },
  {
    path: "/inspections",
    name: "inspections",
    component: () => import("../components/ReportsPage.vue"),
    // component: ComingSoonView,
    beforeEnter: guardMyRoute,
    meta: {
      title: "Inspections",
      metaTags: [
        {
          name: "description",
          content: "Inspections",
        },
      ],
    },
  },
  {
    path: "/assets/:assetId",
    name: "view asset link",
    component: () => import("../components/ViewAssetLink.vue"),
    // component: ComingSoonView,
    beforeEnter: viewAssetGuard,
    meta: {
      title: "Sites",
      metaTags: [
        {
          name: "description",
          content: "View Sites",
        },
      ],
    },
  },
  {
    path: "/inspections/:id",
    name: "view inspection",
    component: ComingSoonView,
    beforeEnter: guardMyRoute,
    meta: {
      title: "View Inspection",
      metaTags: [
        {
          name: "description",
          content: "View Inspection",
        },
      ],
    },
  },
  {
    path: "/clients",
    name: "clients",
    component: () => import("../components/clientsFlow/ClientsPage.vue"),
    // component: ComingSoonView,
    beforeEnter: guardMyRoute,
    meta: {
      title: "Clients",
      metaTags: [
        {
          name: "description",
          content: "Clients",
        },
      ],
    },
  },
  {
    path: "/clients/:clientId/sites",
    name: "sites",
    component: () => import("../components/clientsFlow/ClientSites.vue"),
    // component: ComingSoonView,
    beforeEnter: guardMyRoute,
    meta: {
      title: "Sites",
      metaTags: [
        {
          name: "description",
          content: "View Sites",
        },
      ],
    },
  },
  {
    path: "/clients/:clientId/sites/:siteId",
    name: "view site apps",
    component: () => import("../components/clientsFlow/SiteApps.vue"),
    // component: ComingSoonView,
    beforeEnter: guardMyRoute,
    meta: {
      title: "Site Apps",
      metaTags: [
        {
          name: "description",
          content: "Site Applications",
        },
      ],
    },
  },
  {
    path: "/clients/:clientId/sites/:siteId/apps/:appId",
    name: "view site inspections",
    component: () => import("../components/clientsFlow/ClientInspections.vue"),
    // component: ComingSoonView,
    beforeEnter: guardMyRoute,
    meta: {
      title: "Inspections",
      metaTags: [
        {
          name: "description",
          content: "Site Inspections",
        },
      ],
    },
  },
  {
    path: "/clients/:clientId/sites/:siteId/apps/:appId/inspections/:inspectionId",
    name: "view inspection",
    component: () =>
      import("../components/clientsFlow/ViewInspectionsPage.vue"),
    // component: ComingSoonView,
    beforeEnter: guardMyRoute,
    meta: {
      title: "View Inspection",
      metaTags: [
        {
          name: "description",
          content: "Site Assets",
        },
      ],
    },
  },
  {
    path: "/clients/:clientId/sites/:siteId/apps/:appId/inspections/:inspectionId/asset/:assetId",
    name: "view asset",
    component: () => import("../components/clientsFlow/ViewAsset.vue"),
    // component: ComingSoonView,
    beforeEnter: guardMyRoute,
    meta: {
      title: "View Asset",
      metaTags: [
        {
          name: "description",
          content: "View Asset",
        },
      ],
    },
  },
  {
    path: "/sites/:id",
    name: "view site",
    // component: () => import("../views/ViewSitePageView.vue"),
    component: ComingSoonView,
    beforeEnter: guardMyRoute,
    meta: {
      title: "View Sites",
      metaTags: [
        {
          name: "description",
          content: "View Sites",
        },
      ],
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../components/MembersPage.vue"),
    // component: ComingSoonView,
    beforeEnter: guardMySuperAdminRoute,
    meta: {
      title: "Users",
      metaTags: [
        {
          name: "description",
          content: "Users",
        },
      ],
    },
  },
  // global q&a
  {
    path: "/globalqa/dashboard",
    name: "global qa - dashboard",
    component: ComingSoonView,
    beforeEnter: guardMySuperAdminRoute,
    meta: {
      title: "Global QA - Dashboard",
      metaTags: [
        {
          name: "description",
          content: "Global QA - Dashboard",
        },
      ],
    },
  },
  {
    path: "/globalqa/certifications",
    name: "global qa - certifications",
    component: ComingSoonView,
    beforeEnter: guardMySuperAdminRoute,
    meta: {
      title: "Global QA - Certifications",
      metaTags: [
        {
          name: "description",
          content: "Global QA - Certifications",
        },
      ],
    },
  },
  {
    path: "/globalqa/members",
    name: "global qa - members",
    component: ComingSoonView,
    beforeEnter: guardMySuperAdminRoute,
    meta: {
      title: "Global QA - Members",
      metaTags: [
        {
          name: "description",
          content: "Global QA - Members",
        },
      ],
    },
  },
  {
    path: "/globalqa/applications",
    name: "global qa - applications",
    component: () => import("../components/globalqa/ApplicationsPage.vue"),
    beforeEnter: guardMySuperAdminRoute,
    meta: {
      title: "Global QA - Applications",
      metaTags: [
        {
          name: "description",
          content: "Global QA - Applications",
        },
      ],
    },
  },
  {
    path: "/globalqa/applications/:id",
    name: "global qa - view templates",
    component: () => import("../components/globalqa/ViewTemplates.vue"),
    beforeEnter: guardMySuperAdminRoute,
    meta: {
      title: "Global QA - View Templates",
      metaTags: [
        {
          name: "description",
          content: "Global QA - View Templates",
        },
      ],
    },
  },
  {
    path: "/globalqa/applications/:id/create-template/:templateId",
    name: "global qa - create template",
    component: () => import("../components/globalqa/CreateTemplate.vue"),
    beforeEnter: guardMySuperAdminRoute,
    meta: {
      title: "Global QA - Create Template",
      metaTags: [
        {
          name: "description",
          content: "Global QA - Create Template",
        },
      ],
    },
  },
  // {
  //   // this is QRCodesGrid Component
  //   path: "/asset-labels",
  //   name: "asset labels",
  //   component: () => import("../views/assetLabels/AssetLabelsView.vue"),
  //   meta: {
  //     title: "Asset Labels",
  //     metaTags: [
  //       {
  //         name: "description",
  //         content: "Asset Labels",
  //       },
  //     ],
  //   },
  // },
  {
    // this is @/components/assetLabels/AssetLabelApps.vue component
    path: "/asset-labels/apps",
    name: "asset labels",
    component: () => import("../components/assetLabels/AssetLabelApps.vue"),
    beforeEnter: guardMySuperAdminRoute,
    meta: {
      title: "Asset Labels",
      metaTags: [
        {
          name: "description",
          content: "Asset Labels",
        },
      ],
    },
  },
  {
    // this is @/components/assetLabels/LabelOrders.vue component
    path: "/asset-labels/apps/:appId/orders",
    name: "asset labels - orders",
    component: () => import("../components/assetLabels/LabelOrders.vue"),
    beforeEnter: guardMySuperAdminRoute,
    meta: {
      title: "Asset Labels",
      metaTags: [
        {
          name: "description",
          content: "Asset Labels",
        },
      ],
    },
  },
  {
    // this is QRCodesGrid Component
    path: "/asset-labels/apps/:appId/orders/:orderId",
    name: "asset labels - codes",
    component: () => import("../components/assetLabels/QrCodesGrid.vue"),
    beforeEnter: guardMySuperAdminRoute,
    meta: {
      title: "Asset Labels",
      metaTags: [
        {
          name: "description",
          content: "Asset Labels",
        },
      ],
    },
  },
  {
    path: "/changelog",
    name: "changelog",
    component: ChangeLogView,
    meta: {
      title: "Change Log",
      metaTags: [
        {
          name: "description",
          content: "Change Log",
        },
      ],
    },
  },
  {
    path: "/live-chat",
    name: "live chat",
    component: ComingSoonView,
    meta: {
      title: "Live Chat",
      metaTags: [
        {
          name: "description",
          content: "Live Chat",
        },
      ],
    },
  },
  {
    path: "/billing",
    name: "billing",
    component: ComingSoonView,
    meta: {
      title: "Billing",
      metaTags: [
        {
          name: "description",
          content: "Billing",
        },
      ],
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/TermsView.vue"),
    meta: {
      title: "Terms",
      metaTags: [
        {
          name: "description",
          content: "Terms",
        },
      ],
    },
  },
  {
    path: "/cookies",
    name: "cookies",
    component: () => import("../views/CookiesView.vue"),
    meta: {
      title: "Cookies",
      metaTags: [
        {
          name: "description",
          content: "Cookies",
        },
      ],
    },
  },
  {
    path: "/help",
    name: "help",
    component: ComingSoonView,
    meta: {
      title: "Help",
      metaTags: [
        {
          name: "description",
          content: "Help",
        },
      ],
    },
  },
  {
    path: "/send-feedback",
    name: "send feedback",
    component: ComingSoonView,
    meta: {
      title: "Send Feedback",
      metaTags: [
        {
          name: "description",
          content: "Send Feedback",
        },
      ],
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ComingSoonView,
    meta: {
      title: "Profile",
      metaTags: [
        {
          name: "description",
          content: "Profile",
        },
      ],
    },
  },
  {
    path: "/organisation",
    name: "organisation",
    component: ComingSoonView,
    meta: {
      title: "Organisation",
      metaTags: [
        {
          name: "description",
          content: "Organisation",
        },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  // Use the router's meta field to get the metaTags array
  const metaTags = to.meta.metaTags as Array<{ [key: string]: string }>;

  // Remove any old meta tags
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode?.removeChild(el)
  );

  // Add the new meta tags
  if (metaTags) {
    metaTags.forEach((tag) => {
      const newTag = document.createElement("meta");
      Object.keys(tag).forEach((key) => {
        newTag.setAttribute(key, tag[key]);
      });
      newTag.setAttribute("data-vue-router-controlled", "");
      document.head.appendChild(newTag);
    });
  }

  // Set the document title
  document.title =
    typeof to.meta.title === "string" ? to.meta.title : "Default Title";
});

export default router;
