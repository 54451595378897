import { createStore } from "vuex";

export default createStore({
  state: {
    userRole: 0,
    token: null,
    loggedInUser: false,
    isOpen: false,
  },
  mutations: {
    setUserRole(state, role) {
      state.userRole = role;
    },
    setToken(state, token) {
      state.token = token;
    },
    setLoggedInUser(state, isLoggedIn) {
      state.loggedInUser = isLoggedIn;
    },
    toggleIsOpen(state) {
      state.isOpen = !state.isOpen;
    },
    setIsOpen(state, value) {
      state.isOpen = value;
    },
  },
  actions: {
    checkRoute({ commit }, route) {
      commit("setIsOpen", route.includes("globalqa"));
    },
  },
});
