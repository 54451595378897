<template>
  <div>
    <!-- Mobile Menu -->
    <TransitionRoot :show="sidebarOpen" as="template">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-100/80 dark:bg-black-light/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    class="-m-2.5 p-2.5"
                    type="button"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      class="h-6 w-6 text-black dark:text-off-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component -->
              <div
                class="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-black px-6 pb-4 ring-1 ring-white/10"
              >
                <div class="flex h-10 shrink-0 items-center mt-10 mb-5 pr-10">
                  <img
                    v-if="isDark"
                    alt="Your Company"
                    class="w-auto"
                    src="https://spaceplus-dcuk.ams3.cdn.digitaloceanspaces.com/TAGR/logos/tagr-white.png"
                  />
                  <img
                    v-else-if="!isDark"
                    alt="Your Company"
                    class="w-auto"
                    src="https://spaceplus-dcuk.ams3.cdn.digitaloceanspaces.com/TAGR/logos/tagr-black.png"
                  />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul class="flex flex-1 flex-col gap-y-5" role="list">
                    <li>
                      <ul class="-mx-2 space-y-1" role="list">
                        <li v-for="item in filteredNavigation" :key="item.name">
                          <router-link
                            v-if="!item.children"
                            :class="[
                              route.path.startsWith(item.href)
                                ? 'bg-gray-300 text-gray-800 dark:bg-black-dark dark:text-gray-200 shadow-inner'
                                : 'hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-black-dark',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                            ]"
                            :to="item.href"
                          >
                            <component
                              :is="item.icon"
                              :class="[
                                route.path.startsWith(item.href)
                                  ? 'text-indigo-600'
                                  : 'text-gray-400 group-hover:text-indigo-600',
                                'h-6 w-6 shrink-0',
                              ]"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </router-link>
                          <Disclosure v-else :open="isOpen" as="div">
                            <DisclosureButton
                              :class="[
                                route.path.includes(item.href)
                                  ? 'bg-gray-300 text-gray-800 dark:bg-black-dark dark:text-gray-200 shadow-inner'
                                  : 'hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-black-dark',
                                'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700',
                              ]"
                              @click="toggleOpen()"
                            >
                              <ChevronRightIcon
                                :class="[
                                  isOpen
                                    ? 'rotate-90 text-gray-500'
                                    : 'text-gray-400',
                                  'h-5 w-5 shrink-0',
                                ]"
                                aria-hidden="true"
                              />
                              {{ item.name }}
                            </DisclosureButton>
                            <KeepAlive>
                              <DisclosurePanel
                                as="ul"
                                class="mt-1 px-2 text-left"
                              >
                                <li
                                  v-for="subItem in item.children"
                                  :key="subItem.name"
                                >
                                  <router-link
                                    :class="[
                                      route.path.includes(subItem.href)
                                        ? 'bg-gray-300 text-gray-800 dark:bg-black-dark dark:text-gray-200 shadow-inner'
                                        : 'hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-black-dark',
                                      'group flex gap-x-3 p-2 font-semibold rounded-md py-2 pr-2 pl-8 text-sm leading-6 text-gray-700',
                                    ]"
                                    :to="subItem.href"
                                  >
                                    <component
                                      :is="subItem.icon"
                                      :class="[
                                        route.path.includes(subItem.href)
                                          ? 'text-indigo-600'
                                          : 'text-gray-400 group-hover:text-indigo-600',
                                        'h-6 w-6 shrink-0',
                                      ]"
                                      aria-hidden="true"
                                    />
                                    {{ subItem.name }}
                                  </router-link>
                                </li>
                              </DisclosurePanel>
                            </KeepAlive>
                          </Disclosure>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div
                        class="text-xs font-semibold leading-6 text-gray-400 text-left"
                      >
                        Support
                      </div>
                      <ul class="-mx-2 mt-2 space-y-1" role="list">
                        <li v-for="team in filteredSupport" :key="team.name">
                          <router-link
                            :class="[
                              route.path.includes(team.href)
                                ? 'bg-gray-300 text-gray-800 dark:bg-black-dark dark:text-gray-200 shadow-inner'
                                : 'hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-black-dark',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                            ]"
                            :to="team.href"
                          >
                            <component
                              :is="team.icon"
                              :class="[
                                route.path.includes(team.href)
                                  ? 'text-indigo-600'
                                  : 'text-gray-400 group-hover:text-indigo-600',
                                'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg  text-[0.625rem] font-medium ',
                              ]"
                              >{{ team.initial }}
                            </component>
                            <span class="truncate">{{ team.name }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="flex justify-center items-center mt-auto">
                      <Switch
                        v-model="isDark"
                        :class="[
                          isDark ? 'bg-indigo-600' : 'bg-gray-300',
                          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                        ]"
                        class="transition ease-in-out duration-200"
                        @click="toggleDark"
                      >
                        <span class="sr-only">Use setting</span>
                        <span
                          :class="[
                            isDark
                              ? 'translate-x-5 duration-100 ease-out'
                              : 'translate-x-0',
                            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                          ]"
                        >
                          <span
                            :class="[
                              isDark
                                ? 'opacity-0 duration-100 ease-out'
                                : 'opacity-100 duration-200 ease-in',
                              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                            ]"
                            aria-hidden="true"
                          >
                            <SunIcon
                              aria-hidden="true"
                              class="h-3 w-3 text-gray-600"
                            />
                          </span>
                          <span
                            :class="[
                              isDark
                                ? 'opacity-100 duration-200 ease-in'
                                : 'opacity-0 duration-100 ease-out',
                              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                            ]"
                            aria-hidden="true"
                          >
                            <MoonIcon
                              aria-hidden="true"
                              class="h-3 w-3 text-gray-600"
                            />
                          </span>
                        </span>
                      </Switch>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col lg:bg-off-white dark:bg-black border-r border-gray-300 dark:border-gray-700"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 dark:border-black-light bg-white dark:bg-black px-6 pb-4"
      >
        <div class="flex h-16 shrink-0 items-center mt-5">
          <img
            v-if="isDark"
            alt="Your Company"
            class="w-auto"
            src="https://spaceplus-dcuk.ams3.cdn.digitaloceanspaces.com/TAGR/logos/tagr-white.png"
          />
          <img
            v-else-if="!isDark"
            alt="Your Company"
            class="w-auto"
            src="https://spaceplus-dcuk.ams3.cdn.digitaloceanspaces.com/TAGR/logos/tagr-black.png"
          />
        </div>
        <nav class="flex flex-1 flex-col">
          <ul class="flex flex-1 flex-col gap-y-5" role="list">
            <li>
              <ul class="-mx-2 space-y-1" role="list">
                <li v-for="item in filteredNavigation" :key="item.name">
                  <router-link
                    v-if="!item.children"
                    :class="[
                      route.path.startsWith(item.href)
                        ? 'bg-gray-300 text-gray-800 dark:bg-black-dark dark:text-gray-200 shadow-inner'
                        : 'hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-black-dark',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]"
                    :to="item.href"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        route.path.startsWith(item.href)
                          ? 'text-indigo-600'
                          : 'text-gray-400 group-hover:text-indigo-600',
                        'h-6 w-6 shrink-0',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </router-link>
                  <Disclosure v-else :open="isOpen" as="div">
                    <DisclosureButton
                      :class="[
                        route.path.includes(item.href)
                          ? 'bg-gray-300 text-gray-800 dark:bg-black-dark dark:text-gray-200 shadow-inner'
                          : 'hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-black-dark',
                        'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700',
                      ]"
                      @click="toggleOpen"
                    >
                      <ChevronRightIcon
                        :class="[
                          isOpen ? 'rotate-90 text-gray-500' : 'text-gray-400',
                          'h-5 w-5 shrink-0',
                        ]"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </DisclosureButton>
                    <KeepAlive>
                      <DisclosurePanel as="ul" class="mt-1 px-2 text-left">
                        <li
                          v-for="subItem in item.children"
                          :key="subItem.name"
                        >
                          <router-link
                            :class="[
                              route.path.includes(subItem.href)
                                ? 'bg-gray-300 text-gray-800 dark:bg-black-dark dark:text-gray-200 shadow-inner'
                                : 'hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-black-dark',
                              'group flex gap-x-3 p-2 font-semibold rounded-md py-2 pr-2 pl-8 text-sm leading-6 text-gray-700',
                            ]"
                            :to="subItem.href"
                          >
                            <component
                              :is="subItem.icon"
                              :class="[
                                route.path.includes(subItem.href)
                                  ? 'text-indigo-600'
                                  : 'text-gray-400 group-hover:text-indigo-600',
                                'h-6 w-6 shrink-0',
                              ]"
                              aria-hidden="true"
                            />
                            {{ subItem.name }}
                          </router-link>
                        </li>
                      </DisclosurePanel>
                    </KeepAlive>
                  </Disclosure>
                </li>
              </ul>
            </li>
            <li>
              <div
                class="text-xs font-semibold leading-6 text-gray-400 text-left"
              >
                Support
              </div>
              <ul class="-mx-2 mt-2 space-y-1" role="list">
                <li v-for="team in filteredSupport" :key="team.name">
                  <router-link
                    :class="[
                      route.path.includes(team.href)
                        ? 'bg-gray-300 text-gray-800 dark:bg-black-dark dark:text-gray-200 shadow-inner'
                        : 'hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-black-dark',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]"
                    :to="team.href"
                  >
                    <component
                      :is="team.icon"
                      :class="[
                        route.path.includes(team.href)
                          ? 'text-indigo-600'
                          : 'text-gray-400 group-hover:text-indigo-600',
                        'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg  text-[0.625rem] font-medium ',
                      ]"
                      >{{ team.initial }}
                    </component>
                    <span class="truncate">{{ team.name }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="flex justify-center items-center mt-auto">
              <Switch
                v-model="isDark"
                :class="[
                  isDark ? 'bg-indigo-600' : 'bg-gray-300',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                ]"
                class="transition ease-in-out duration-200"
                @click="toggleDark"
              >
                <span class="sr-only">Use setting</span>
                <span
                  :class="[
                    isDark
                      ? 'translate-x-5 duration-100 ease-out'
                      : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  ]"
                >
                  <span
                    :class="[
                      isDark
                        ? 'opacity-0 duration-100 ease-out'
                        : 'opacity-100 duration-200 ease-in',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                    ]"
                    aria-hidden="true"
                  >
                    <SunIcon aria-hidden="true" class="h-3 w-3 text-gray-600" />
                  </span>
                  <span
                    :class="[
                      isDark
                        ? 'opacity-100 duration-200 ease-in'
                        : 'opacity-0 duration-100 ease-out',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                    ]"
                    aria-hidden="true"
                  >
                    <MoonIcon
                      aria-hidden="true"
                      class="h-3 w-3 text-gray-600"
                    />
                  </span>
                </span>
              </Switch>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- Content Area -->
    <div class="lg:pl-60 h-full overflow-auto">
      <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-black px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
      >
        <button
          class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          type="button"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon
            aria-hidden="true"
            class="h-6 w-6 text-black dark:text-off-white"
          />
        </button>

        <!-- Separator -->
        <div
          aria-hidden="true"
          class="h-6 w-px bg-light-background dark:bg-gray-400 lg:hidden"
        />

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form
            v-if="route.path === '/inspections'"
            class="relative flex flex-1"
            @submit.prevent="emitInspectionSearch"
          >
            <label class="sr-only" for="search-field">Search</label>
            <MagnifyingGlassIcon
              aria-hidden="true"
              class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
            />
            <input
              id="search-field"
              :key="inputKey"
              v-model="inspectionSearch"
              class="block h-full w-full dark:bg-black border-0 py-0 pl-8 pr-0 text-gray-900 dark:text-off-white placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              name="search"
              placeholder="Search..."
              required
              type="text"
              @keyup="emitInspectionSearch"
            />
          </form>
          <form
            v-else
            class="relative flex flex-1"
            @submit.prevent="emitSearch"
          >
            <label class="sr-only" for="search-field">Search</label>
            <MagnifyingGlassIcon
              aria-hidden="true"
              class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
            />
            <input
              id="search-field"
              :key="inputKey"
              v-model="searchQuery"
              class="block h-full w-full dark:bg-black border-0 py-0 pl-8 pr-0 text-gray-900 dark:text-off-white placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              name="search"
              placeholder="Search..."
              required
              type="text"
            />
          </form>
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <!-- Profile dropdown -->
            <Menu as="div" class="relative">
              <MenuButton
                class="-m-1.5 flex items-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <span class="sr-only">Open user menu</span>
                <BellIcon aria-hidden="true" class="h-6 w-6" />
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white dark:bg-black py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                >
                  <MenuItem v-slot="{ active }">
                    <div
                      :class="[
                        active ? 'bg-gray-50 dark:bg-black-light' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-off-white',
                      ]"
                    >
                      Your report is ready!
                      <br />
                      <span class="text-gray-500 text-xs">2 minutes ago</span>
                      <br />
                      <!--                      // download link-->
                      <a
                        class="mt-1 inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        href="#"
                        target="_blank"
                      >
                        Download
                      </a>
                    </div>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
            <Menu as="div" class="relative">
              <MenuButton class="-m-1.5 flex items-center p-1.5">
                <span class="sr-only">Open user menu</span>
                <UserIcon
                  aria-hidden="true"
                  class="h-8 w-8 text-gray-400 dark:text-off-white dark:bg-gray-500 bg-gray-200 rounded-full p-1"
                />
                <span class="hidden lg:flex lg:items-center">
                  <span
                    aria-hidden="true"
                    class="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-off-white"
                    >{{ `${user.first_name} ${user.last_name}` }}</span
                  >
                  <ChevronDownIcon
                    aria-hidden="true"
                    class="ml-2 h-5 w-5 text-gray-400"
                  />
                </span>
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white dark:bg-black py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <router-link
                      :class="[
                        active ? 'bg-gray-50 dark:bg-black-light' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-off-white',
                      ]"
                      :to="item.href"
                      >{{ item.name }}
                    </router-link>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <main class="py-10 bg-light-background dark:bg-black-dark h-full">
        <div
          class="px-4 sm:px-6 lg:px-8 bg-light-background dark:bg-black-dark h-full"
        >
          <!-- Your content -->
          <slot></slot>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, onMounted, onUnmounted, ref } from "vue";
import { useDark, useToggle } from "@vueuse/core";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Switch,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3Icon,
  BellIcon,
  CodeBracketIcon,
  DocumentTextIcon,
  HomeIcon,
  QrCodeIcon,
  QuestionMarkCircleIcon,
  Squares2X2Icon,
  UsersIcon,
  XMarkIcon,
  BuildingOfficeIcon,
} from "@heroicons/vue/24/outline";
import { MoonIcon, SunIcon } from "@heroicons/vue/24/solid";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  UserIcon,
} from "@heroicons/vue/20/solid";
import { useRoute } from "vue-router";
import Pusher from "pusher-js";

const user = JSON.parse(localStorage.getItem("user"));
const userRole = user ? user.role : null;
const clientId = user ? user.client_id : null;

const navigation = [
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon, role: 0 },
  { name: "Clients", href: "/clients", icon: UsersIcon, role: 0 },
  {
    name: "Inspections",
    href: "/inspections",
    icon: DocumentTextIcon,
    role: 0,
  },
  {
    name: "Asset Labels",
    href: "/asset-labels/apps",
    icon: QrCodeIcon,
    role: 2,
  },
  {
    name: "Global Q&A",
    icon: Squares2X2Icon,
    role: 2,
    href: "/globalqa/applications",
  },
  {
    name: "Users",
    href: "/users",
    icon: UsersIcon,
    role: 2,
  },
];
if (clientId && userRole !== 2) {
  navigation[1].href = `/clients/${clientId}/sites`;
  navigation[1].icon = BuildingOfficeIcon;
  navigation[1].name = "Sites";
}
const userNavigation = [{ name: "Sign out", href: "/logout" }];

const teams = [
  // {
  //   id: 1,
  //   name: "T&C's",
  //   href: "/terms",
  //   initial: "T",
  //   current: false,
  //   icon: DocumentTextIcon,
  //   role: 0,
  // },
  {
    id: 1,
    name: "Cookie Policy",
    href: "/cookies",
    initial: "C",
    current: false,
    icon: QuestionMarkCircleIcon,
    role: 0,
  },
  {
    id: 2,
    name: "Changelog",
    href: "/changelog",
    initial: "C",
    current: false,
    icon: CodeBracketIcon,
    role: 2,
  },
];

const sidebarOpen = ref(false);
const isOpen = ref(false);
const isDark = useDark();
const toggleDark = useToggle(isDark);
const route = useRoute();

const filteredNavigation = computed(() => {
  if (userRole === null || userRole === undefined) {
    return [];
  }
  return navigation.filter((item) => userRole >= item.role);
});

const filteredSupport = computed(() => {
  if (userRole === null || userRole === undefined) {
    return [];
  }
  return teams.filter((item) => userRole >= item.role);
});

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

const emit = defineEmits(["search", "inspectionSearch"]);

const searchQuery = ref("");
const inspectionSearch = ref("");

const emitSearch = () => {
  emit("search", searchQuery.value);
};
const emitInspectionSearch = () => {
  emit("inspectionSearch", inspectionSearch.value);
};
let inputKey = ref(0);

const pusher = ref(null);

onMounted(() => {
  const pusherKey = process.env.VUE_APP_PUSHER_KEY;

  pusher.value = new Pusher(pusherKey, {
    cluster: "eu",
  });

  const channel = pusher.value.subscribe("TAGR");

  channel.bind("pusher:subscription_succeeded", () => {
    console.log("Successfully connected to the channel");
  });

  channel.bind("report-ready", (data) => {
    console.log(data);
  });
});

onUnmounted(() => {
  const channel = pusher.value.channel("TAGR");

  if (channel) {
    channel.unbind("report-ready");
    pusher.value.unsubscribe("TAGR");
  }
});
</script>
