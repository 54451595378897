<template>
  <div>
    <div
      class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-6 sm:flex-row sm:items-center"
    >
      <div>
        <div class="flex items-center gap-x-3">
          <h1 class="flex gap-x-3 text-sm sm:text-2xl leading-7 items-center">
            <span class="font-semibold text-gray-900 dark:text-white"
              >Changelog</span
            >
            <span class="text-gray-500 dark:text-gray-400">/</span>
            <span class="font-semibold text-gray-900 dark:text-white">{{
              `v0.4`
            }}</span>
            <span class="text-gray-900 dark:text-white text-sm"
              >(current version)</span
            >
          </h1>
        </div>
        <p
          class="mt-2 text-xs sm:text-sm leading-6 text-gray-600 dark:text-gray-400 text-left"
        >
          View notes for the latest version of TAGR. Everything listed on this
          page is considered working and ready for testing.
        </p>
      </div>
    </div>
    <ul class="space-y-3">
      <li v-for="item in changelog" :key="item.version">
        <div class="flex items-center gap-x-3">
          <h2 class="text-sm font-semibold text-gray-900 dark:text-white">
            {{ item.version }} - ({{ item.title }})
          </h2>
          <span class="text-gray-500 dark:text-gray-400">/</span>
          <span class="text-sm text-gray-500 dark:text-gray-400">{{
            item.date
          }}</span>
          <span></span>
        </div>
        <ul
          class="mt-2 text-xs sm:text-sm leading-6 text-gray-600 dark:text-gray-400 text-left"
        >
          <li
            v-for="change in item.changes"
            :key="change"
            class="list-disc ml-4"
          >
            {{ change }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "ChangeLogView",
  data() {
    return {
      changelog: [
        {
          title: "Inspections",
          version: "v0.4",
          date: "2024-03-26",
          changes: [
            "View and schedule inspections",
            "Filtered by status: 'Pending Approval', 'Approved', 'Uncompleted'",
            "Ability to Approve inspections that are Pending Approval",
            "Click inspection name to jump to its place within the Clients flow",
          ],
        },
        {
          title: "Clients",
          version: "v0.3",
          date: "2024-03-26",
          changes: [
            "View edit and create clients + sites",
            "Flow is as follows: Clients -> Sites -> Apps -> Inspections",
          ],
        },
        {
          title: "Asset Labels",
          version: "v0.2",
          date: "2024-02-23",
          changes: [
            "View and create asset labels",
            "Generates unique QR codes for each label",
            "Labels assigned based on applications",
            "Flow is as follows: Applications -> Orders -> Labels",
          ],
        },
        {
          title: "Global QA beta",
          version: "v0.1",
          date: "2024-01-22",
          changes: [
            "View and create 'Applications'",
            "View templates belonging to an application",
            "Create new templates",
            "Create new version of existing templates, where questions, ordering, and logic are duplicated",
            "Use 'Template builder' to add custom questions, logic, and ordering (accessible from templates -> edit template)",
            "Publish a template -- locking it down for further editing",
            "Further instructions present on each page",
          ],
        },
      ],
    };
  },
};
</script>
