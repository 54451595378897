<template>
  <router-view
    v-if="
      [
        'login',
        'register account',
        'forgot-password',
        'logout',
        'reset-password',
        'login - view asset',
      ].indexOf($route.name) > -1
    "
  />
  <SidebarLayout
    v-else
    @search="handleSearch"
    @inspection-search="handleInspectionSearch"
  >
    <router-view />
  </SidebarLayout>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#app::-webkit-scrollbar {
  display: none;
}
</style>

<script>
import SidebarLayout from "@/layouts/SidebarLayout.vue";
import { computed } from "vue";

export default {
  components: { SidebarLayout },
  data() {
    return {
      searchQuery: "",
      inspectionSearch: "",
      pusher: null,
      pusherKey: null,
    };
  },
  methods: {
    handleSearch(newQuery) {
      this.searchQuery = newQuery;
    },
    handleInspectionSearch(newQuery) {
      this.inspectionSearch = newQuery;
    },
  },
  provide() {
    return {
      searchQuery: computed(() => this.searchQuery),
      inspectionSearch: computed(() => this.inspectionSearch),
      handleSearch: this.handleSearch,
      handleInspectionSearch: this.handleInspectionSearch,
    };
  },
  created() {
    // this.pusherKey = process.env.VUE_APP_PUSHER_KEY;

    const storedRole = localStorage.getItem("role");
    const loggedUser = localStorage.getItem("loggedUser");
    const token = localStorage.getItem("token");
    if (storedRole) {
      this.$store.commit("setUserRole", storedRole);
    }
    if (loggedUser === "true") {
      this.$store.commit("setLoggedInUser", true);
    } else {
      this.$store.commit("setLoggedInUser", false);
    }
    if (token) {
      this.$store.commit("setToken", token);
    }
  },
};
</script>
