<template>
  <div class="flex flex-col justify-center items-center h-full my-auto mx-auto">
    <div>
      <img
        v-if="isDark && route.path.includes('/globalqa')"
        alt="Your Company"
        class="w-96"
        src="@/assets/logos/qa-logo-white.png"
      />
      <img
        v-else-if="!isDark && route.path.includes('/globalqa')"
        alt="Your Company"
        class="w-96"
        src="@/assets/logos/qa-logo-black.png"
      />
      <img
        v-else-if="!isDark && !route.path.includes('/globalqa')"
        alt="Your Company"
        class="w-96"
        src="@/assets/logos/tagr-pb-black.png"
      />
      <img
        v-else
        alt="Your Company"
        class="w-96"
        src="@/assets/logos/tagr-pb-white.png"
      />
    </div>
    <h1 class="font-bold text-2xl mt-3 dark:text-white">Coming soon</h1>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { useDark } from "@vueuse/core";

export default {
  name: "ComingSoonView",

  setup() {
    const route = useRoute();
    const isDark = useDark();

    return {
      route,
      isDark,
    };
  },
};
</script>
